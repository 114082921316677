<div
  class="row p-0 p-t-20 flex-wrap"
  [class.fix-search-padding]="isHeaderSource"
  [class.po-page]="!isControl"
>
  <div class="request-list">
    <app-table
      [tableData]="proposals"
      [tableHeaders]="tableHeaders"
      [tableActionIcons]="tableIcons"
      [tableBadges]="tableBadges"
      (tableAction)="onTableAction($event)"
      [tableOffset]="tableConfig.offset"
      [tableLimit]="50"
      [tableCount]="tableConfig.count"
      [externalPaging]="true"
    ></app-table>
  </div>
  @if (!isHeaderSource) {
    <div class="nav-buttons">
      <button
        (click)="changePage(1)"
        class="btn arow-button"
        [class.btn-disable]="currentPage === 1"
        [class.btn-grey]="currentPage === 1"
        [class.btn-black]="currentPage !== 1"
        type="button"
      >
        <img
          class="prev-arow"
          src="assets/images/icons/multiarrowleft.svg"
          alt=""
        />
      </button>
      <button
        (click)="prevPage()"
        class="btn arow-button"
        [class.btn-disable]="currentPage === 1"
        [class.btn-grey]="currentPage === 1"
        [class.btn-black]="currentPage !== 1"
        type="button"
      >
        <img class="prev-arow" src="assets/images/icons/arrowleft.svg" alt="" />
      </button>
      @if (currentPage <= maxPages - 5) {
        @for (
          page of [
            currentPage,
            currentPage + 1,
            currentPage + 2,
            currentPage + 3,
            currentPage + 4
          ];
          track $index
        ) {
          @if (page <= maxPages && page > 0) {
            <button
              class="btn"
              [class.btn-disable]="currentPage === page"
              [class.btn-grey]="currentPage === page"
              [class.btn-black]="currentPage !== page"
              (click)="changePage(page)"
            >
              {{ page }}
            </button>
          }
        }
      }
      @if (currentPage > maxPages - 5) {
        @for (
          page of [
            maxPages - 4,
            maxPages - 3,
            maxPages - 2,
            maxPages - 1,
            maxPages
          ];
          track $index
        ) {
          @if (page <= maxPages && page > 0) {
            <button
              class="btn"
              [class.btn-disable]="currentPage === page"
              [class.btn-grey]="currentPage === page"
              [class.btn-black]="currentPage !== page"
              (click)="changePage(page)"
            >
              {{ page }}
            </button>
          }
        }
      }
      <button
        (click)="nextPage()"
        class="btn arow-button"
        [class.btn-disable]="currentPage === maxPages"
        [class.btn-grey]="currentPage === maxPages"
        [class.btn-black]="currentPage !== maxPages"
        type="button"
      >
        <img
          class="next-arow"
          src="assets/images/icons/arrowright.svg"
          alt=""
        />
      </button>
      <button
        (click)="changePage(maxPages)"
        class="btn arow-button"
        [class.btn-disable]="currentPage === maxPages"
        [class.btn-grey]="currentPage === maxPages"
        [class.btn-black]="currentPage !== maxPages"
        type="button"
      >
        <img
          class="next-arow"
          src="assets/images/icons/multiarrowright.svg"
          alt=""
        />
      </button>
    </div>
  }
</div>
